import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
/** Pass untouched request through to the next request handler. */
@Injectable()

export class NoopInterceptor implements HttpInterceptor {

    constructor(private router: Router, public authService: AuthService, private inj: Injector) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const Home = this.inj.get(AuthService);
        let token: string = JSON.parse(localStorage.getItem('CL_TOKEN'));
        if (token != null) {
            req = req.clone({ headers: req.headers.set('Authorization', token) });
        }
        let self = this;
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                // console.log(error)
                if (error.status === 401) {
                    alert("UnAuthorized")
                    //    Home.notify('error', 'Session Expired. Please Login again.' , 1000000);
                    //    Home.logout();
                }
                if (error.status === 0) {
                    alert(error.message)
                }
                data = {
                    reason: error.message,
                    status: error.status,
                };
                return throwError(error);
            })
        );
    }
}