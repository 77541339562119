import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, forkJoin } from 'rxjs';
import { catchError, map, tap, debounceTime, debounce } from 'rxjs/operators';
import { interval } from 'rxjs';
import { ApiUrls } from '../../apiUrls';
import { Constants } from '../../constants';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    })
};


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient, private router: Router) { }

    getDashboardData() {
        return this.http.get(ApiUrls.DASHBOARD_DATA);
    }

    getApiUsage(type) {
        if (type == 'get') {
            return this.http.get(ApiUrls.API_USAGE);
        } else if (type == 'post') {
            return this.http.post(ApiUrls.API_USAGE, {}, httpOptions);
        }
    }

}
