import { Component, OnInit } from '@angular/core';
import { SocketService, AuthService, AddcoinService } from '../@core'
@Component({
    selector: 'app-test-coin-api-ids-with-socket',
    templateUrl: './test-coin-api-ids-with-socket.component.html',
    styleUrls: ['./test-coin-api-ids-with-socket.component.scss']
})
export class TestCoinApiIdsWithSocketComponent implements OnInit {

    items = [{ display: "BINANCE_SPOT_BTC_USDT", value: "BINANCE_SPOT_BTC_USDT" }];
    priceUpdates = {};
    socketStatus = 0;

    constructor(
        private socketService: SocketService,
        private authService: AuthService,
        private addCoinService: AddcoinService
    ) { }

    ngOnInit() {
    }


    onTagEdited(event) {
        console.log(event)
    }


    connectToSocket() {
        this.socketStatus = 0;

        this.priceUpdates = {}
        let ids = this.items.map(ele => {
            this.priceUpdates[ele.value] = {}
            return ele.value
        });

        if (!ids.length) {
            this.authService.openSnackBar("Please enter atleast 1 id.")
        }
        this.addCoinService.connectToTestSocket(ids).subscribe(resp => {
            this.socketService.connectToSocketForIDtest([])
        });
        this.socketStatus = 1;
        this.socketService.pricesFromTestingSocket.subscribe((resp: any) => {
            if (resp) {
                this.priceUpdates[resp.symbol_id] = resp;
            }
        })

    }

    stopSocketTest() {
        this.socketStatus = 0;
        this.priceUpdates = {}
        this.socketService.stopTestSocket();
        this.addCoinService.stopSocketTest().subscribe(resp => {
            console.log(resp)
        })
    }

    ngOnDestroy() {
        this.priceUpdates = {}
        this.stopSocketTest();
    }
}
