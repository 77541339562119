import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable, BehaviorSubject, of } from "rxjs";
import { environment } from '../../../../environments/environment';
// import { TradingService } from '../trading/trading.service';
@Injectable({
	providedIn: 'root'
})
export class SocketService {

	private url = environment.baseUrl;
	private socket;
	id;
	isLogout = false;
	pricesFromSocket = new BehaviorSubject(undefined);
	pricesFromTestingSocket = new BehaviorSubject(undefined);
	// orderBook = new BehaviorSubject(undefined);
	cid;
	IDsocketTest: any;


	constructor(
		// private tradingService: TradingService
	) {
		// this.goOnline({ id: 1 })
	}

	goOnline(profile) {
		try {
			if (this.socket) {
				this.socket.destroy();
				delete this.socket;
				this.socket = null;
			}
			this.id = profile._id;
			this.socket = io(this.url, {
				reconnection: true,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				reconnectionAttempts: Infinity,
				query: {
					id: profile._id
				}
			});
			this.socket.emit('connectAdmin', { msg: 'Connected' });
			// this.socket.on('onlineusers', (msg) => {
			//     this.onLineUsers.next(msg);
			// });
			this.socket.on('disconnect', (err) => {
				if (!this.isLogout) {
					setTimeout(() => {
						this.goOnline({ _id: this.id });
					}, 5000)
				}
			});
			this.socket.on('connect_error', (err) => {
			});
			// this.socket.on('orderBook', (msg) => {
			//     this.orderBook.next(msg)
			// });

			this.socket.on('adminPrices', (msg) => {
				this.pricesFromSocket.next(msg)
			});

			// if (this.cid) {
			//     this.connectToOrderBook(this.cid)
			// } else {
			// }

		} catch (error) {
		}
	}

	// connectToOrderBook(cid) {
	//     this.orderBook.next(undefined)
	//     this.cid = cid;
	//     this.socket.emit('orderBook', { cid });
	// }

	connectToRealTimePrices() {

	}

	goOffLine() {
		this.isLogout = true;
		this.socket.disconnect();
		this.socket.destroy();
	}

	// public sendMessage(message) {
	// }

	connectToSocketForIDtest(ids) {
		this.pricesFromTestingSocket.next(undefined)

		try {

			if (this.IDsocketTest) {
				this.IDsocketTest.destroy();
				delete this.IDsocketTest;
				this.IDsocketTest = null;
			}

			this.IDsocketTest = io(this.url, {
				reconnection: true,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				reconnectionAttempts: Infinity,

			});

			console.log(this.IDsocketTest)

			this.IDsocketTest.on('disconnect', (err) => {
				console.log(err)
			});
			this.IDsocketTest.on('connect_error', (err) => {
				console.log(err)

			});
			// this.socket.on('orderBook', (msg) => {
			//     this.orderBook.next(msg)
			// });

			this.IDsocketTest.on('socketapitesting', (msg) => {
				this.pricesFromTestingSocket.next(msg)
			});


		} catch (error) {
			console.log(error)
		}
	}

	stopTestSocket() {
		this.pricesFromTestingSocket.next(undefined)

		if (this.IDsocketTest) {
			this.IDsocketTest.destroy();
			delete this.IDsocketTest;
			this.IDsocketTest = null;
		}
	}


}
