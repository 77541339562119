import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, forkJoin } from 'rxjs';
import { catchError, map, tap, debounceTime, debounce } from 'rxjs/operators';
import { interval } from 'rxjs';
import { ApiUrls } from '../../apiUrls';
import { Constants } from '../../constants';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    })
};

const httpOptionsFormdata = {
    headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
    })
};


@Injectable({
    providedIn: 'root'
})
export class AddcoinService {

    constructor(private http: HttpClient, private router: Router) { }

    validateFirstStep(payload) {
        return this.http.post(ApiUrls.VALIDATE, payload, httpOptions);
    }

    checkPriceForId(payload) {
        return this.http.post(ApiUrls.CHECK_PRICE_FORID, payload, httpOptions);

    }

    verifyCmcId(payload) {
        return this.http.post(ApiUrls.VERIFY_CMC_ID, payload, httpOptions);
    }

    getAssetList() {
        return this.http.post(ApiUrls.GET_LIST_ASSET, {}, httpOptions)
    }

    addCoin(formData) {
        return this.http.post(ApiUrls.ADD_COIN, formData)
    }

    connectToTestSocket(ids) {
        return this.http.post(ApiUrls.TEST_COINAPI_IDS, ids, httpOptions);
    }

    stopSocketTest() {
        return this.http.get(ApiUrls.STOP_SOCKET_TEST);
    }

}
