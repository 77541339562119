import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: String;
  strs = "New Notification"
  constructor(private titleService: Title) {
    this.title = this.strs;
    // setTimeout(() => {
    //   // alert("update")
    // }, 5000)
    // setInterval(() => {
    //   this.setTitle()
    // }, 500)
  }

  public setTitle() {
    if (this.title.length == 0) {
      this.title = this.strs
    }
    let t = this.title.slice(1)
    this.titleService.setTitle(t);
  }


}
