import { Injectable } from '@angular/core';
import { ApiUrls } from '../../apiUrls';
import { HttpClient, HttpHeaders } from '@angular/common/http';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    })
};


@Injectable({
    providedIn: 'root'
})
export class UsersService {
    // leagueid = '';

    constructor(private http: HttpClient) { }

    // public getUsersData(page, size) {
    //     return this.http.get(ApiUrls.GET_USERS + 'page=' + page + '&size=' + size);
    // }

    public getUsers(search, page, size, sort) {

        let url = ApiUrls.GET_USERS + '&page=' + page + '&size=' + size;
        if (search) {
            url = url + '&search=' + search
        }
        if (sort) {
            url = url + '&active=' + sort.active + '&dir=' + sort.sort
        }
        return this.http.get(url);
    }

    public getUsersMetrics(search, page, size, sort) {

        let url = ApiUrls.GET_USERS_METRICS + '&page=' + page + '&size=' + size;
        if (search) {
            url = url + '&search=' + search
        }
        if (sort) {
            url = url + '&active=' + sort.active + '&dir=' + sort.sort
        }
        return this.http.get(url);
    }

    public getleagueUsers(id, page, size, sort) {

        let url = ApiUrls.GET_LEAGUE_USERS + '&page=' + page + '&size=' + size + '&id=' + id;
        // if (search) {
        //     url = url + '&search=' + search
        // }
        if (sort) {
            url = url + '&active=' + sort.active + '&dir=' + sort.sort
        }
        return this.http.get(url);
    }


    getUsersDataForExcel() {
        return this.http.get(ApiUrls.GET_USERS_FOR_EXCEL)
    }

    getLeagueUsersDataForExcel() {
        let leagueid = localStorage.getItem('lid')
        return this.http.get(ApiUrls.GET_LEAGUE_USERS_FOR_EXCEL + "/?id=" + leagueid)
    }


    tradehistory(uid, lid, getList) {
        return this.http.get(ApiUrls.TRADE_HISTORY + "/" + uid + "/" + lid + "/" + getList)
    }

    sendNotification(payload) {
        return this.http.post(ApiUrls.SEND_NOTIFICATION, payload, httpOptions)
    }

    sendEmailsToUnverifiedAccount() {
        return this.http.get(ApiUrls.SEND_EMAIL_TO_UNVERIFIED_USERS)
    }

    getUserDetail(id) {
        return this.http.get(ApiUrls.GET_USER_DETAIL + "/" + id)
    }

}
